<template>
  <div>
    <present-dish-form :save="save" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PresentDishForm from './present-dish-form.vue'

export default {
  name: 'PresentDishEdit',
  components: { PresentDishForm },
  async created() {
    document.title = 'Редактирование товара'
    const { id } = this.$route.params
    this.fetchById(id)
  },
  methods: {
    async save(image) {
      await this.$store.dispatch('customDishes/form/update', image)
      await this.$router.push({ name: 'present-dish-list' })
    },
    ...mapActions('customDishes/form', ['fetchById']),
  },
}
</script>

<style scoped>

</style>
